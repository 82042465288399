/***************************
 * = COULEURS
 ***************************/

$color-primary: #{"var(--color-primary, #000)"};
$color-primary-dark: #{"var(--color-primary-dark, #333)"};
$color-primary-light: #{"var(--color-primary-light, #555)"};

$color-secondary: #{"var(--color-secondary, #36b0ec)"};
$color-secondary-dark: #{"var(--color-secondary-dark, #2b91c3)"};
$color-secondary-light: #{"var(--color-secondary-light, #4fbff6)"};

//$color-primary: #000;
//$color-primary-dark: #333;
//$color-primary-light: #555;
//$color-secondary: #36b0ec;
//$color-secondary-dark: #2b91c3;
//$color-secondary-light: #4fbff6;

$black: #000;
$grey-dark: #333;
$grey-light: #f4f4f4;
$grey-medium: #ccc;
$color-error: #a94442;
$color-valid: #0abf74;
$color-info: #3493dc;

/***************************
 * = FONTS
 ***************************/

%font-normal {
	font-family: HelvNeue, sans-serif;
}

%font-bold {
	font-family: HelvNeueBold, sans-serif;
}

%text {
	@extend %font-normal;

	font-size: 1.4rem;
	line-height: 1.5;
	color: $black;
}

%text-bold {
	@extend %font-bold;

	font-size: 1.4rem;
	line-height: 1.5;
	color: $black;
}

%text-secondary {
	@extend %font-normal;

	font-size: 1.4rem;
	line-height: 1.5;
	color: $grey-dark;
}

%text-secondary-bold {
	@extend %font-bold;

	font-size: 1.4rem;
	line-height: 1.5;
	color: $grey-dark;
}

%link {
	@extend %font-bold;

	font-size: 1.4rem;
	line-height: 1.5;
	color: $color-secondary;
	cursor: pointer;
	text-decoration: none;

	@include gridle_state(md) {
		&:hover {
			text-decoration: underline;
		}
	}
}

%master-button {
	border-radius: 0;
}

/***************************
 * = COMMUN
 ***************************/

$border-radius-small: 0;
$border-radius-big: 0;
$border-radius-top: 0 0 0 0;
$border-radius-bottom: 0 0 $border-radius-big $border-radius-big;
$footer-background: #727272; // on a décidé de prendre le gris de la brand XX pour différencier le footer OV de notre footer
$footer-border: 1px solid $grey-dark;
$footer-text-color: white;
$header-background-color: $black;
$header-border-bottom: none;
$header-menu-active-color: $color-secondary;
$header-text-color: white;
$header-text-transform: uppercase;

$menu-account-menu-item-color: white;
$menu-account-submenu-item-color: white;

$auth-header-background-color: white;
$auth-header-mobile-background-color: $auth-header-background-color;
$auth-header-text-color: $black;
$auth-header-mobile-text-color: $auth-header-text-color;
$cookie-policy-footer-background: $black;
$cookie-policy-footer-color: white;
$cookie-policy-footer-text-transform: uppercase;
$phone-number-color: $black;
$common-box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.16);
$badge-background-color: $color-primary;

$payment-banner-background-color: $color-secondary;
$hamburger-layer-color: white;

/***************************
 * = FORM
 ***************************/

$active-color: $color-secondary;
$active-color-dark: $color-secondary-dark;
$active-color-light: $color-secondary-light;
$input-border-color: $grey-medium;
$input-border: 1px solid var(--color-active, $active-color);
$input-border-radius: $border-radius-small;
$radio-checked-background: $grey-light;
$radio-hover-border-color: $active-color-light;
$radio-outline: none;
$radio-outline-error: none;
$input-shadow-box: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
$button-primary-background-color: $color-secondary;
$button-primary-background-color-dark: $color-secondary-dark;
$button-box-shadow: none;

/***************************
 * = FICHE PRODUIT
 ***************************/
$product-accommodation-text-transform: uppercase;
$product-deal-text-color: white;
$offer-mobile-background-color: $color-primary;
$bloc-contact-title-case: uppercase;
$bloc-contact-body-background: $color-primary;
$bloc-contact-text-color: $black;
$product-aside-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
$datepicker-background-color: $color-primary;

/***************************
 * = MOTEUR DE RECHERCHE
 ***************************/

$search-engine-offer-color: $black;

/***************************
 * = TUNNEL DE REZA | COMMUN
 ***************************/
$booking-bloc-title-main-background-color: $color-primary;
$booking-item-margin: 15px;
$booking-cta-background-color: $color-valid;
$quotation-price-detail-toggle-button-background: $color-primary;
$quotation-price-detail-total-background: $color-primary;
$quotation-price-detail-total-border: 1px solid $color-primary;
$quotation-price-detail-total-font-color: white;
$quotation-price-detail-pay-now-border: 1px solid white;
$quotation-price-detail-text-color: white;
$quotation-price-detail-total-margin-bottom: 10px;

/***************************
 * = TUNNEL DE REZA | QUOTE
 ***************************/
$booking-badge-background-color: $color-primary;
$badge-font-color: white;
$badge-border: none;
$badge-border-radius: 40px;
$quotation-filter-sort-background: white;
$flight-filter-button-background-color: $grey-light;
$flight-filter-button-color: $black;
$flight-filter-button-border-radius: $border-radius-big;
$flight-filter-background-color: $active-color;
$quotation-luggage-included-border-radius: $border-radius-small;
$quotation-luggage-included-outline: unset;

/***************************
 * = TUNNEL DE REZA | PAYMENT
 ***************************/
$insurances-radio-second-color: #051039;

/***************************
 * = TUNNEL DE REZA | CONFIRMATION
 ***************************/
$booking-confirmation-reference-color: $black;

/***************************
 * = LISTING
 ***************************/
$product-cta-background-color: $button-primary-background-color;
$product-cta-border: 1px solid $button-primary-background-color;
$product-cta-button-radius: 0;
$product-deal-public-price-line-through-color: $black;
$product-intemporelle-color: $color-primary;
$listing-actionbar-title-display-on-mobile: block;
$listings-product-background-color: $grey-light;
$listing-header-height: 273px;
$colored-product-badge-background-color: $color-primary;
$colored-product-badge-text-transform: uppercase;
$colored-product-badge-border-radius: 0;
$merchandising-simple-width: 100%;
$listing-time-border-radius: 50px;
$listing-time-background-color: $colored-product-badge-background-color;

/***************************
 * = AUTH
 ***************************/
// auth form background that appears on a background that do not change (auth, booking/auth, home)
$auth-form-background-static: rgba(0, 0, 0, 0.22) !default;
$auth-form-text-color-static: white !default;

// auth form background that appears on a background that can change over time (sign-up, landing product)
$auth-form-background-dynamic: rgba(255, 255, 255, 0.8) !default;
$auth-form-text-color-dynamic: $black !default;
$auth-marketing-message-shadow: linear-gradient(
	to bottom,
	rgba(4, 45, 87, 0.86),
	rgba(39, 83, 128, 0)
) !default;

/***************************
 * = PLACEHOLDERS
 ***************************/

%booking-title {
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	box-sizing: border-box;
	text-align: center;
	min-height: 40px;
	padding: 10px 0;
}

%input-label-touched {
	position: absolute;
	top: 0;
	left: 5px;
	font-size: 1rem;
	color: var(--color-active, $active-color);
	background: none;
	padding-right: 10px;
	padding-left: 10px;
}

$auth-background-color: #27c8ee;
$listing-header-filter-max-width: none;
$listing-header-sticky-container-box-shadow: none;
$product-filter-header-box-shadow: none;
$products-filter-selected-border: none;
$products-master-filter-box-shadow: unset;
$aside-product-filters-menu-filters-title-height: 40px;
$marketing-slide-small-logo: url("/static/icons/icon-marketing-slide-small-logo-af.svg");
$marketing-slide-small-1: url("/static/icons/icon-marketing-slide-small-af-1.svg");
